footer {
  padding-top: max(2.5em, 12vh);
  background-color: $--black;

  .contact-info {
    padding: 0 clamp(1.25em, 4vw, 2.5em);
    max-width: 100em;
    margin: 0 auto;

    @media (min-width: 720px) {
        padding: 0 12vw;
    }

    @media (min-width: 1200px) {
        padding: 0 304px;
    }
  }

  .profile-pic {
    margin-right: 2px;
    img {
      width: calc(clamp(3.25em, 7vw, 8em) * 0.67);
      height: calc(clamp(3.25em, 7vw, 8em) * 0.67);
      border-radius: 50%;
      background-color: $--grey;
      object-fit: cover;
      object-position: top;
    }
  }

  h2 span {
    font-size: calc(clamp(3.25em, 7vw, 8em) * 0.875);
    color: $--white;
    font-weight: 400;
  }

  h2 {
    display: block;
    padding-bottom: max(2.5rem, 8vh);
  }

  .together {
    display: block;
    position: relative;
  }

  .footer-arrow {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
  }

  .arrow {
    transform: rotate(90deg);
    position: absolute;
    top: 0;
    right: 0;
    width: clamp(0.9rem, 1.1vw, 1.1rem);
  }
  .cta-container {
    margin-bottom: max(2.5em, 16vh);
  }

  hr {
    border-color: darken($--grey, 20);
  }

  .cta {
    position: absolute;
    bottom: calc(100% - 8px - clamp(9rem, 12vw, 11rem) / 2);
    right: 8vw;
    background: $--blue;
    width: clamp(9rem, 12vw, 11rem);
    height: clamp(9rem, 12vw, 11rem);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    span {
      color: $--white;
    }
  }

  .btns {
    margin-bottom: max(2.5em, 7vh);
    @media (min-width: 600px) {
        display: inline-flex;
        
    }
}

.first-btn {
    margin-bottom: 0.75rem;
    margin-right: 10px;
    @media (min-width: 600px) {
        margin-bottom: 0;
    }
}

.btn {
    background: none;
    width: 100%;
    cursor: pointer;
    color: $--white;
    border: solid 1px darken($--grey, 20);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 2.5rem;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 105%;
        height: 100%;
        background: $--blue;
        transition: transform 0.5s;
        transform-origin: bottom;
        transform: scaleY(0);
    }

    &:hover::before {
        transform: scaleY(1);
    }
  }

  a {
    z-index: 10;
    text-decoration: none;
    &:link {
      color: $--white;
    }

    &:active {
      color: $--white;
    }
    &:visited {
      color: $--white;
    }
  }
  
  .version-and-time {
    color: $--white;
    padding: 0 clamp(1.25em, 4vw, 2.5em);
    margin-bottom: 40px;
    .row {
        justify-content:space-between;
    }
    
    h5 {
        font-size: 10px;
        font-weight: 300;
        padding-bottom: 20px;
        color: $--grey;
    }
    p {
      font-size: 13px;
    }
  }
}
