.about {
  background-color: $--white;
  padding: max(2.5em, 12vh) 0;

  .container {
    padding: 0 clamp(1.25em, 4vw, 2.5em);

    @media (min-width: 720px) {
      padding: 0 10vw;
    }

    h4 {
      font-size: clamp(1.55em, 2.3vw, 2.5em);
      font-weight: 450;
      line-height: 150%;

      @media (min-width: 720px) {
        width: 70%;
        padding-right: clamp(1.5em, 4vw, 2.5em);
      }
    }

    .text-wrap {
      padding-top: calc(max(2.5em, 12vh) / 2);
      position: relative;
      width: 100%;

      @media (min-width: 720px) {
        width: 30%;
        padding-top: 0;
        padding-left: clamp(1.5em, 4vw, 2.5em);
      }

      p {
        line-height: 150%;
        @media (min-width: 600px) {
          display: inline-block;
          max-width: 25em;
        }

        @media (min-width: 720px) {
          display: inline-block;
          margin-bottom: 10em;
          max-width: 14.5em;
        }
      }

      span {
        display: inline-block;
        max-width: 60%;
        @media (min-width: 600px) {
          display: inline-block;
          max-width: 30em;
        }
      }

      .about-me {
        position: absolute;
        bottom: -100px;
        right: -10px;
        width: clamp(9em, 12vw, 11em);
        height: clamp(9em, 12vw, 11em);
        border-radius: 50%;
        background: $--black;
        display: flex;
        color: $--white;
        justify-content: center;
        align-items: center;

        @media (min-width: 500px) {
          top: 25%;
        }

        @media (min-width: 720px) {
          top: unset;
          bottom: -5em;
          left: 3em;
        }
      }
    }
  }
}
