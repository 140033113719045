.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 13;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.side-nav {
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: $--black;

  @media (min-width: 720px) {
    width: 50vw;
  }

  .side-nav-inner {
    padding: calc(1vh * 15) clamp(1.25em, 4vw, 2.5em)
      calc(clamp(1.5em, 4vw, 2.5em) * 1.25) clamp(1.25em, 4vw, 2.5em);
  }

  h5 {
    font-size: 10px;
    font-weight: 400;
    color: $--grey;
    margin-bottom: 29px;
  }

  hr {
    display: block;
    width: 100%;
    height: 1px;
    border-color: $--grey;
    background-color: $--grey;
  }

  ul {
    list-style: none;

    li {
      margin: 1rem 0;
      
      h2 {
          font-size: calc(clamp(3.25em, 5vw, 4em) * 0.875);
          font-weight: 450;
          color: $--white;
          cursor: pointer;
          display: inline;
      }
    }
  }
}
