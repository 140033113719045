//animation speeds
$--animation-primary: all .5s cubic-bezier(.7, 0, .3, 1);

// padding
$--gap-padding: clamp(1.5em, 4vw, 2.5em);

// colors
$--grey: #999D9E;
$--black: #1C1D20;
$--white: #FFFFFF;
$--blue: #455CE9;
